import React from "react";
import styled from "styled-components";
import ImageSlide from "../../components/ImageSlide";
// import GLOBALS from "../../utils/globals";
// import Button from "../../components/Button";
// import GLOBALS from "../../utils/globals"

const StyledLanding = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  // margin: 7rem 0;
  // margin-top: 1.5rem;
  // margin-bottom: 4rem;
  // border-bottom: var(--border);

  @media only screen and (max-width: 1000px) {
    // margin-top: 0rem;
    
  }
`;

// const Header = styled.h1`
//   font-size: 7.5rem;
//   font-weight: var(--bold);
//   text-align: center;
//   // max-width: 110rem;
//   margin-top: 0rem;
//   // margin-bottom: 5rem;
//   text-transform: uppercase;
//   // -webkit-text-stroke:1px black;
//   text-shadow: 0 0 12px #FF0000, 0 0 20px #0000FF;

//   @media only screen and (max-width: 1000px) {
//     font-size: 6.5rem;
//     line-height: 6.5rem;    
//     // margin-bottom: 1rem;
//   }
// `;

// const SubHeader = styled.h4`
//   font-size: 2.8rem;
//   font-weight: var(--bold);
//   text-align: center;
//   margin-bottom: 4rem;
//   max-width: 90rem;
//   // -webkit-text-stroke:1px black;
//   text-shadow: 0 0 6px #FF0000, 0 0 10px #0000FF;

//   @media only screen and (max-width: 1000px) {
//     font-size: 2.4rem;
//     // margin-top: 18rem;
//     // margin-bottom: 2rem;
//     max-width: 60rem;
//   }
// `;

const SubHeader2 = styled.h4`
  font-size: 4rem;
  // font-weight: var(--light);
  text-align: center;
  // margin-top: rem;
  margin-bottom: 2rem;

  @media only screen and (max-width: 1000px) {
    font-size: 3rem;    
    margin-bottom: 2rem;    
    margin-top: 3rem;
  }
`;

const Details = styled.p`
  font-size: 2.6rem;
  font-weight: var(--light);
  max-width: 85rem;
  text-align: center;
  // margin-bottom: 8rem;
  line-height: 140%;
  margin-bottom: 6rem;

  a {
    font-size: 2.6rem;
    text-decoration: underline;
  }  

  @media only screen and (max-width: 1000px) {
    font-size: 2.2rem;

    a {
      font-size: 2.2rem;
      text-decoration: underline;
    }      
  }
`;

const Details2 = styled.p`
  margin-top: 6rem;
  font-size: 2.6rem;
  font-weight: var(--light);
  max-width: 90rem;
  text-align: center;
  margin-bottom: 6rem;
  line-height: 140%;

  @media only screen and (max-width: 1000px) {
    font-size: 2.2rem;
    // margin-bottom: 5rem;
  }
`;

// const Details3 = styled.p`
//   font-size: 2.2rem;
//   font-weight: var(--light);
//   max-width: 85rem;
//   text-align: center;
//   margin-top: 6rem;
//   margin-bottom: 2rem;

//   @media only screen and (max-width: 1000px) {
//     font-size: 2.2rem;
//   }
// `;

// const After = styled.p`
//   font-size: 1.6rem;
//   font-weight: var(--light);
//   text-align: center;
// `;

// const Billionaires = styled.div`
//   display: grid;
//   grid-template-columns: repeat(3, 1fr);
//   grid-gap: 4rem;
//   margin: 0 5rem;
//   margin-top: 4.5rem;

//   @media only screen and (max-width: 1000px) {
//     grid-template-columns: repeat(2, 1fr);
//     grid-gap: 2rem;
//     margin: 0;
//     margin-top: 4.5rem;
//   }

//   @media only screen and (min-width: 1001px) {
//     div:nth-child(4) {
//       display: none;
//     }
//   }
// `;

// const Billioniare = styled.div`
//   padding: 1.5rem;
//   border: var(--border);

//   @media only screen and (max-width: 1000px) {
//     margin-bottom: 0rem;
//   }
// `;

// const BillionaireImage = styled.img`
//   width: 30rem;

//   @media only screen and (max-width: 1000px) {
//     width: 100%;
//   }
// `;

const Landing = () => {
  return (
    <StyledLanding>
      {/* <Header>
        Bitcoin Billionaires
      </Header>
      <SubHeader>
        A collection of unique pixel art NFTs from the creators of Bitcoin Billionaire, the hit mobile game with over 10 million downloads.
      </SubHeader> */}
      {/* <WBContainer>
        <WBImage src = {wb_arcade}>          
        </WBImage>
      </WBContainer> */}
      {/* <VideoContainer>
      <Video autoPlay loop muted playsInline>
          <source src="/PromoAnimation1.mp4" type="video/mp4"></source>
      </Video>
      </VideoContainer> */}
      <SubHeader2>
        Your Billionaire. Your Story.
      </SubHeader2>
      <Details>
        Bitcoin Billionaires is a collection of 13,337 unique pixel art NFTs. 
      </Details>
      {/* <Details>
        Join our <a target='_blank' rel='noreferrer' href={GLOBALS.DISCORDURL}>Discord</a> to learn about our community, roadmap, and access giveaways!
      </Details> */}
      <ImageSlide/>
      <Details2>
        These aspiring crypto Billionaires are composed of over 200+ unique traits.
        Show them off, create their stories, or just hodl until the end of time. 
        <br />
        It's your call.
      </Details2>
      {/* <Details3>
        Join our Discord for early access, presale, and giveaways.
      </Details3>
      <Button click={() => window.open(GLOBALS.DISCORDURL, '_blank')?.focus()} text={"Join Discord"} /> */}
      {/* <Details2>
        MINT Coming Soon
      </Details2> */}
      {/* <Billionaires>
        {billionaires.map((b: string) => (
          <Billioniare>
            <BillionaireImage src={b} />
          </Billioniare>
        ))}
      </Billionaires> */}
    </StyledLanding>
  );
};

export default Landing;
