import React, { useRef } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
// import background from "../../assets/backgrounds/highlights.svg";
import race_human1 from "../../assets/races/human1.png";
// import race_human2 from "../../assets/races/human2.png";
import race_zombie1 from "../../assets/races/zombie1.png";
// import race_ethereal1 from "../../assets/races/ethereal1.png";
import race_ethereal3 from "../../assets/races/ethereal3.png";
import race_alien1 from "../../assets/races/alien1.png";
import race_golden3 from "../../assets/races/golden3.png";
// import anim_mimic from "../../assets/animations/stickers/Mimic@3x.gif";
import race_question1 from "../../assets/races/QuestionMarkBlue.png";
// import race_question2 from "../../assets/races/QuestionMarkPurple.png";
import wb_ufo from "../../assets/wb/UFO.png";
import imageAnim_Mimic from "../../assets/wb/animations/WB_Mimic.gif";
import imageAnim_SnowGlobe from "../../assets/wb/animations/WB_FutureSnowGlobe.gif";
import imageAnim_HoloDesk from "../../assets/wb/animations/WB_HoloDesk.gif";


type RaceType = {
  name: string;
  image: string;
  text: string;
};

// const URL1337Ref: string = "https://en.wikipedia.org/wiki/Leet";
//const URLBBGame : string = "https://fizzpow.com";

const races_data: RaceType[] = [
  {
    name: "Humans",
    image: race_human1,
    text:
      "In a galaxy crowded with other, wackier species, Humans are just doing their best to get noticed. What could be more attention-grabbing than becoming an eccentric billionaire?",
  },
  {
    name: "Zombies",
    image: race_zombie1,
    text:
      "Tired of being seen as mindless, shambling brain eaters, the Zombies are hiring a PR firm to turn their public image around. They're gonna need a pile of crypto to do that.",
  },
  {
    name: "Ethereals",
    image: race_ethereal3,
    text:
      "Having long ago evolved beyond the need for physical forms, Ethereals thought it would be great if everyone experienced the infinite joy of being made of pure light. Evolving ain't cheap, though, so they're gonna need tons of crypto to make it happen.",
  },
  {
    name: "Aliens",
    image: race_alien1,
    text:
      "These space-wandering nomads mined all of the cryptocurrency from their homeworld (wait, what?). Now they seek new sources of interstellar income to keep their economy afloat.",
  },
  {
    name: "Goldens",
    image: race_golden3,
    text:
      "Beings that have existed since the dawn of time. They became billionaires before the first atoms formed, which is a pretty big accomplishment if you think about it.",
  },
  {
    name: "Legendaries",
    image: race_question1,
    text:
      "Beings so incredibly rare that many insist they don't exist. Did they leave the galaxy to seek their mega-billions in other dimensions? Are they just a myth? Only a lucky few will ever find out.",
  },
];

const StyledRaces = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  // margin-top: 5rem;
  margin-bottom: 4rem;
  max-width: 1000px;

  margin-left: auto;
  margin-right: auto;

  @media only screen and (max-width: 1000px) {
    margin-top: 0rem;
    margin-bottom: 4rem;
  }
`;

const Header = styled.div`
  position: relative;
  // width: 200%;
  // left: 25%;
  // transform: translate(-12.5%);
  border-top: var(--border);
  border-bottom: var(--border);
  text-shadow: 0 0 12px #ff0000, 0 0 20px #0000ff;

  // height: 100%;
  width: 100vw;
  // width: 100%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);

  overflow: hidden;
  height: 45rem;
  background-color: #000f1c;
  margin-bottom: 2rem;

  @media only screen and (max-width: 1000px) {
    height: 25rem;
    width: 100vw;
    margin-bottom: 1rem;
  }
`;

const ImageHeaderContainer = styled.div`
  display: flex;
  height: 100%;
`;

const ImageHeaderContent = styled.div`
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  position: relative;
  transform: translateX(-3%);
  @media only screen and (max-width: 1000px) {
    transform: translateX(-23%);
  }
`;

const ImageAnimMimic = styled.img`
  position: absolute;
  width: 8.8%;
  bottom: 4.55%;
  right: 34.6%;
`;

const ImageAnimSnowGlobe = styled.img`
  position: absolute;
  width: 5.8%;
  bottom: 4.8%;
  left: 37.8%;
`;

const ImageAnimHoloDesk = styled.img`
  position: absolute;
  width: 16.1%;
  bottom: 8.8%;
  left: 42.1%;
`;

const ImageHeader = styled.img`
  height: 100%;
`;

const HeaderText = styled.h2`
  position: absolute;
  font-size: 5.5rem;
  font-weight: var(--light-bold);
  top: 50%;
  left: 50%;
  // transform: translate(15%, -50%);
  transform: translate(15%, -200%);
  text-transform: uppercase;
  text-align: right;

  @media only screen and (max-width: 1000px) {
    font-size: 4rem;
    transform: translate(-5%, -100%);
    line-height: 100%;
  }
`;

const RaceGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @media only screen and (max-width: 1000px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const RacesContainer = styled.div`
  display: flex;
  // flex-direction: column;
  align-items: center;
  padding: 2.5rem;
  // padding-left: 15rem;

  @media only screen and (max-width: 1000px) {
    padding: 2rem 0;
    border-right: none;
    flex-direction: row;
  }
`;

const RaceImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2.5rem;
  width: 25rem;
  padding-right: 3rem;

  @media only screen and (max-width: 1000px) {
    width: 16rem;
    margin-bottom: 0;
    padding-right: 0rem;
  }
`;

const RaceImage = styled.img`
  height: 15rem;
  // image-rendering: pixelated;

  @media only screen and (max-width: 1000px) {
    padding: 0;
    // height: 10rem;
  }
`;

const RaceTextContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 1000px) {
    flex: 1;
  }
`;

const RaceHeaderText = styled.h4`
  font-size: 3rem;
  font-weight: var(--bold);

  @media only screen and (max-width: 1000px) {
    font-size: 2.8rem;
  }
`;

const RaceText = styled.p`
  font-size: 2.2rem;
  // font-weight: var(--x-light);
  text-align: left;
  max-width: 80rem;
  line-height: 140%;

  a {
    font-size: 2rem;
    //text-decoration: underline;
  }

  @media only screen and (max-width: 1000px) {
    font-size: 2rem;
    line-height: 130%;
    max-width: none;
    text-align: left;
    padding-right: 2rem;
    padding-bottom: 1rem;
  }
`;

const SubHeaderText = styled.p`
  font-size: 2.6rem;
  line-height: 140%;
  font-weight: var(--light);
  max-width: 85rem;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3rem;

  @media only screen and (max-width: 1000px) {
    font-size: 2.1rem;
  }
`;

const Species = () => {
  const history = useHistory();
  const scrollRef = useRef<HTMLDivElement>(null);
  history.listen((location) => {
    const i = location.search.search("scroll=");
    if (
      i > -1 &&
      location.search.substring(i + 7, location.search.length - i + 1) ===
      "highlights"
    ) {
      scrollRef.current?.scrollIntoView({
        behavior: "smooth",
      });
    }
  });

  return (
    <StyledRaces ref={scrollRef}>
      <Header>
        <ImageHeaderContainer>
          <ImageHeaderContent>
            <ImageHeader src={wb_ufo} />
            <ImageAnimHoloDesk src={imageAnim_HoloDesk} />
            <ImageAnimMimic src={imageAnim_Mimic} />
            <ImageAnimSnowGlobe src={imageAnim_SnowGlobe} />
          </ImageHeaderContent>
        </ImageHeaderContainer>
        <HeaderText>billionaire species</HeaderText>
      </Header>
      <SubHeaderText>
        Every unique Billionaire is a member of one of the species below,
        each with their own origin and goals within the Billionaire Universe.
      </SubHeaderText>
      <RaceGrid>
        {races_data.map((h: RaceType, index: number) => (
          <RacesContainer>
            <RaceImageContainer>
              <RaceImage src={h.image} />
            </RaceImageContainer>
            <RaceTextContainer>
              <RaceHeaderText>{h.name}</RaceHeaderText>
              <RaceText>{h.text}</RaceText>
            </RaceTextContainer>
          </RacesContainer>
        ))}
      </RaceGrid>
    </StyledRaces>
  );
};

export default Species;
