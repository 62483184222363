import React from "react";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    :root {
      --main: #F5F5F5;
      --sub: #bbb;
      --shadow: #010304;
      --white: #F8FBF5;
      --highlight: rgba(255, 255, 255, 0.7);
      --gold: #F9F12A;
      --bg: #1C1D22;

      //blues
      // --primary-dark: #275289;
      // --primary-light: #0095f3;
      // --primary: #017dcb;
      
      //purples
      --primary-dark: #5146ab;
      --primary-light: #7667F8;
      --primary: #695bdc;
      // --primary: #7667F8;
      
      --border: solid 1px #38383D;
      --bold: 700;
      --light-bold: 600;
      --medium: 500;
      --light: 400;
      --x-light: 300;
      --line-height: 2.6rem;
    }
  
    * {
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      font-family: 'Barlow', sans-serif;
      font-size: 8px;
      color: var(--white);
    }

    h1, h2, h3 ,h4 {
      font-family: 'Chakra Petch', sans-serif;
    }
  
    button {
      background: none;
      border: none;
      outline: none;
    }

    a {
        text-decoration: none;
        color: var(--main);
    }
  `;

const GlobalStyles = () => <GlobalStyle />;

export default GlobalStyles;
