type GlobalsType = {
  BILLIONAIRE_CONTRACT: string;
  AIRDROP_CONTRACT: string;
  RELEASED: boolean;
  PRICE: string;
  REVEAL: Date;
  MAXBUY: number;
  MAX_SUPPLY: number;
  TWITTERURL: string;
  DISCORDURL: string;
  OPENSEA_URL: string;
  DOCS_URL: string;
  LAUNCH_EPOCH: number;
  API_URL: string;
};

const GLOBALS: GlobalsType = {
  BILLIONAIRE_CONTRACT: "0x80d77B4Ae7cD0D7a21Fd3C1B2DA25A4a06B63923",
  AIRDROP_CONTRACT: "0x8d22E38d177aA784f3aC584C38969c0f48ac07c2",
  RELEASED: true,
  PRICE: "0.09",
  REVEAL: new Date(2021, 9, 15),
  MAXBUY: 10,
  MAX_SUPPLY: 13337,
  TWITTERURL: "https://twitter.com/NFTBillionaires",
  DISCORDURL: "https://discord.gg/BillionairesNFT",
  OPENSEA_URL: "https://opensea.io/collection/bitcoinbillionaires",
  DOCS_URL: "https://docs.billionaires.io/",
  API_URL: "https://billionaires.io/api",
  LAUNCH_EPOCH: 1635454800000,
};

export default GLOBALS;
