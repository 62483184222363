import React, { useState } from "react";
// import styled, { keyframes } from "styled-components";
import styled from "styled-components";
import bitcoin from "../assets/bitcoin.png";
import NavItems from "./NavItems";
// import Socials from "./Socials";
import GLOBALS from "../utils/globals";
// import headerImage2 from "../assets/wb/Futuretopia Full.png";
import headerImage1 from "../assets/wb/Arcade Full.png";
// import headerImage from "../assets/backgrounds/header.svg";
// import headerAnim_Arcade1 from "../assets/wb/animations/WB_Arcade1.gif";
import headerAnim_Arcade1 from "../assets/wb/animations/WB_Arcade1.gif";
import headerAnim_Arcade2 from "../assets/wb/animations/WB_Arcade2.gif";
import headerAnim_Arcade3 from "../assets/wb/animations/WB_Arcade3.gif";
import headerAnim_Boombox from "../assets/wb/animations/WB_Boombox.gif";
import headerAnim_Fynns from "../assets/wb/animations/WB_Fynns.gif";
import headerAnim_Claw from "../assets/wb/animations/WB_ClawMachine.gif";

// import { useHistory, useLocation } from "react-router";
import { useHistory } from "react-router";
// import rocket from "../assets/pieces/rocket.png";

const StyledHeader = styled.div`
  position: relative;
  width: 100%;
  // margin-bottom: 1rem;

  @media only screen and (max-width: 1000px) {
    // margin-bottom: 1rem;
  }
`;

const HeaderImageContainer = styled.div`
  // padding: 1.5rem;
  // padding-bottom: 3rem;
  position: relative;

  // top: 0;
  left: -10rem;
  transform: translateY(-5%);

  max-height: 95rem;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  // position: absolute;
  // top: 0;
  // left: -10rem;
  // width: calc(100% + 20rem);
  // transform: translateY(-35%);
  width: 100vw;

  @media only screen and (max-width: 1000px) {
    left: -2rem;
  }
`;

const HeaderImageContent = styled.div`
  position: relative;
  width: 100vw;
`;

const HeaderImage = styled.img`
  // width: calc(100% + 20rem);
  width: 100vw;
  // max-height: 30rem;

  @media only screen and (max-width: 1000px) {
    // width: calc(100% + 25rem);
    // width: 100vw;
  }
`;

// const HeaderImageOverlayAnim1 = styled.img`
//   position: absolute;
//   top: 50%;
//   transform: translateY(-50%);
//   left: 0;
//   width: 100vw;
// `;
// const HeaderImageOverlay = styled.img`
//   position: absolute;
//   top: 50%;
//   transform: translateY(-50%);
//   left: 0;
//   width: 100vw;
//   animation: ${animation} 6s infinite;
// `;

const HeaderAnimArcade1 = styled.img`
  position: absolute;
  width: 12.8%;
  top: 45.35%;
  right: 5.45%;
`;

const HeaderAnimArcade2 = styled.img`
  position: absolute;
  width: 12.8%;
  top: 39.32%;
  right: 18.23%;
`;

const HeaderAnimArcade3 = styled.img`
  position: absolute;
  width: 25.64%;
  top: 38.17%;
  left: 12.2%;
`;

const HeaderAnimBoombox = styled.img`
  position: absolute;
  width: 10.57%;
  bottom: 30.5%;
  left: 6%;
`;

const HeaderAnimFynns = styled.img`
  position: absolute;
  width: 32%;
  top: 10.1%;
  left: 4.18%;
`;

const HeaderAnimClaw = styled.img`
  position: absolute;
  width: 10.89%;
  top: 20.55%;
  left: 42.33%;
`;

// const animation = keyframes`
//   0% {
//     opacity: 0;
//   }
//   5% {
//     opacity: 1;
//   }
//   50% {
//     opacity: 1;
//   }
//   55% {
//     opacity: 0;
//   }
//   100% {
//     opacity: 0;
//   }
// `;

const GradientOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 70%;
  // transform: translateY(5%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));

  @media only screen and (max-width: 1000px) {
    height: 60%;
    // height: 30rem;
    // transform: translateY(9%);
  }
`;

const GradientOverlayBottom = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 0%;
  // transform: translateY(1%);
  background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));

  @media only screen and (max-width: 1000px) {
    height: 30%;
    // transform: translateY(-20%);
  }
`;

const Content = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // max-width: 1000px;
  margin: auto;
`;

const LogoImage = styled.img`
  height: 7.5rem;
  cursor: pointer;

  @media only screen and (max-width: 1000px) {
    height: 5.5rem;
  }
`;

const HideMobile = styled.div`
  display: flex;

  @media only screen and (max-width: 1000px) {
    display: none;
  }
`;

type HamburgerLineProps = {
  small?: boolean;
};

const HamburgerLine = styled.div`
  height: 2px;
  border-radius: 1px;
  background-color: white;
  width: ${(props: HamburgerLineProps) => (props.small ? "60%" : "100%")};
`;

const Hamburger = styled.div`
  height: 4.8rem;
  width: 4.8rem;
  border-radius: 50%;
  background-color: var(--primary);
  padding: 1.6rem 1.4rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media only screen and (min-width: 1001px) {
    display: none;
  }
`;

const MobilePopup = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: var(--primary);
  z-index: 1;
`;
const HorizontalFlex = styled.div`
  display:flex;
`;

const ExitButton = styled.button`
  padding: 2rem 2.7rem;
  font-size: 5.5rem;
  line-height: 3.7rem;
  font-weight: 500;
  color: white;
  position: absolute;
  font-family: "Chakra Petch", sans-serif;
  text-transform: lowercase;
  top: 0;
  right: 0;
`;

// const Rocket = styled.img`
//   width: 10rem;
//   position: absolute;
//   right: 0vw;
//   top: 36.2rem;
//   transform: rotate(-30deg);
//   image-rendering: pixelated;
//   z-index: 2;

//   @media only screen and (max-width: 1000px) {
//     display: none;
//   }
// `;

// const StyledLanding = styled.div`
//   width: 100%;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   // margin: 7rem 0;
//   margin-top: 2rem;
//   margin-bottom: 0rem;

//   @media only screen and (max-width: 1000px) {
//     margin-top: 0rem;
//   }
// `;

const HeaderTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 15%;
  align-items: center;
  text-align: center;
  height: 75%;
  width: 99%;

  @media only screen and (max-width: 1000px) {
    top: 19%;
  }
`;

const HeaderText = styled.h1`
  font-size: 9.5rem;
  font-weight: var(--bold);
  // text-align: center;
  // max-width: 110rem;
  margin-top: 0rem;
  // margin-bottom: 5rem;
  text-transform: uppercase;
  // -webkit-text-stroke:1px black;

  text-shadow: 0 0 12px #ff0000, 0 0 20px #0000ff;

  @media only screen and (max-width: 1000px) {
    margin-top: 1rem;
    font-size: 4rem;
    line-height: 100%;
    max-width: none;
    // margin-bottom: 1rem;
  }
`;

const SubHeader = styled.h4`
  font-size: 3.2rem;
  font-weight: var(--bold);
  // text-align: center;
  max-width: 100rem;
  // margin-bottom: 4rem;
  // -webkit-text-stroke:1px black;
  text-shadow: 0 0 6px #ff0000, 0 0 10px #0000ff;

  @media only screen and (max-width: 1000px) {
    position: absolute;
    // bottom: 0.1%;
    bottom: 0;

    font-size: 2rem;
    line-height: 120%;
    margin-top: 0.5rem;
    // margin-bottom: 2rem;
    // max-width: 99%;
    max-width: 65rem;
    padding-left: 1rem;
    padding-right: 1rem;
    // max-width: 47rem;
  }
`;

const BuyBillionaireLinkButton = styled.a`
  font-size: 3.2rem;
  font-weight: var(--bold);
  text-align: center;
  min-width: 25rem;
  margin: 4rem;
  background: var(--primary);
  padding: 1.5rem;
  padding-left: 3rem;
  padding-right: 3rem;  
  border-radius: 1rem;
  cursor: pointer;
  @media only screen and (max-width: 1000px) {
    margin: 1rem;
    font-size: 2.3rem;
    min-width: 0;
    width: 15rem;
    min-width: 15rem;
  }  
`;

// const SubHeaderMint = styled.h4`
//   position: absolute;
//   bottom: 2rem;
//   // right: 2rem;
//   text-align: center;
//   text-shadow: 0 0 6px #ff0000, 0 0 10px #0000ff;

//   @media only screen and (max-width: 1000px) {
//     bottom: 10rem;
//     // position: absolute;
//     // bottom: 0;

//     // font-size: 2rem;
//     // line-height: 120%;
//     // margin-top: 0.5rem;
//     // max-width: 65rem;
//     // padding-left: 1rem;
//     // padding-right: 1rem;
//   }
// `;
// const SubHeaderMintText = styled.h4`
//   font-size: 2rem;
//   font-weight: var(--bold);
//   max-width: 100rem;
//   text-shadow: 0 0 6px #ff0000, 0 0 10px #0000ff;

//   @media only screen and (max-width: 1000px) {
//     // font-size: 2rem;
//     // line-height: 120%;
//     // margin-top: 0.5rem;
//     // max-width: 65rem;
//     // padding-left: 1rem;
//     // padding-right: 1rem;
//   }
// `;

// const VideoContainer = styled.div`
//   // width: 100vw;
//   @media only screen and (max-width: 1000px) {
//   }
// `;

// const Video = styled.video`
//   width: 100%;
// `;

const BigHeader = () => {
  // const location = useLocation();
  const history = useHistory();
  const [mobilePopup, setMobilePopup] = useState(false);

  return (
    <StyledHeader>
      <HeaderImageContainer>
        <HeaderImageContent>
          {/* <VideoContainer> */}
          {/* <Video autoPlay loop muted playsInline>
            <source src="/FullSizeArcadeHeader.mp4" type="video/mp4"></source>
        </Video> */}
          {/* </VideoContainer>              */}
          <HeaderImage src={headerImage1} />
          <HeaderAnimFynns src={headerAnim_Fynns} />
          <HeaderAnimClaw src={headerAnim_Claw} />
          <HeaderAnimArcade3 src={headerAnim_Arcade3} />
          <HeaderAnimArcade2 src={headerAnim_Arcade2} />
          <HeaderAnimArcade1 src={headerAnim_Arcade1} />
          <HeaderAnimBoombox src={headerAnim_Boombox} />
          <GradientOverlay />
          <GradientOverlayBottom />
        </HeaderImageContent>
        <HeaderTextContainer>
          <HeaderText>Bitcoin Billionaires</HeaderText>
          <SubHeader>
            A collection of quirky, pixel-art NFT avatars inspired by the Bitcoin Billionaire mobile game. Discover yours!
          </SubHeader>
          <br />
          <HorizontalFlex>
            <BuyBillionaireLinkButton onClick={() =>
              (window as any).open(GLOBALS.OPENSEA_URL, "_blank").focus()
            }>
              {" "}
              VIEW ON OPENSEA
            </BuyBillionaireLinkButton>
            {/* <BuyBillionaireLinkButton onClick={() =>
              (window as any).open(GLOBALS.DOCS_URL, "_blank").focus()
            }>
              {" "}
              DOCS
            </BuyBillionaireLinkButton> */}
          </HorizontalFlex>
        </HeaderTextContainer>
        {/* <SubHeaderMint>
            <SubHeaderMintText>
              MINT COMING SOON
            </SubHeaderMintText>
            <SubHeaderMintText>
              Presale Date: October 27th 3PM EDT
            </SubHeaderMintText>
            <SubHeaderMintText>
              Public Sale Date: October 28th 5PM EDT
            </SubHeaderMintText>
          </SubHeaderMint> */}
      </HeaderImageContainer>
      <Content>
        {/* {location.pathname === "/" && <Rocket src={rocket} />} */}
        <LogoImage src={bitcoin} onClick={() => history.push("/")} />
        <HideMobile>
          <NavItems center />
        </HideMobile>
        <HideMobile>
          {/* <Socials /> */}
        </HideMobile>
        <Hamburger onClick={() => setMobilePopup(true)}>
          <HamburgerLine small />
          <HamburgerLine />
          <HamburgerLine small />
        </Hamburger>
      </Content>
      {mobilePopup && (
        <MobilePopup>
          <ExitButton onClick={() => setMobilePopup(false)}>X</ExitButton>
          <NavItems vertical close={() => setMobilePopup(false)} />
          {/* <Socials /> */}
        </MobilePopup>
      )}
    </StyledHeader>
  );
};

export default BigHeader;
