import { useEffect } from "react";

const Init = () => {
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default Init;
