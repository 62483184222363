import styled from "styled-components";

const SubHeaderStyled = styled.h2`
  font-size: 5.5rem;
  font-weight: var(--light-bold);
  text-align: center;
  margin-bottom: 2rem;
  text-shadow: 0 0 12px #ff0000, 0 0 20px #0000ff;
  line-height: 100%;

  @media only screen and (max-width: 1000px) {
    font-size: 4rem;
    margin-bottom: 2rem;
  }
`;

export default SubHeaderStyled;