import React from "react";
import styled from "styled-components";
import bitcoin from "../assets/bitcoin.png";
// import Newsletter from "./Newsletter";
// import Socials from "./Socials";
import footerImage from "../assets/backgrounds/footer.svg";
import { useLocation } from "react-router";

const StyledFooter = styled.div`
  position: relative;
  width: 100%;
  margin-top: 4rem;
  margin-bottom: -4rem;

  @media only screen and (max-width: 1000px) {
    margin-top: 5rem;
    margin-bottom: -2rem;
  }
`;

const BackgroundImage = styled.img`
  position: absolute;
  bottom: 0;
  left: -10rem;
  height: 100%;

  @media only screen and (max-width: 1000px) {
    height: 42rem;
    transform: translateX(-20%);
  }
`;

const Content = styled.div`
  position: relative;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  // border-top: var(--border);
  // border-bottom: var(--border);
  padding: 4rem 0;
  display: flex;
  // max-width: 1000px;
  margin: auto;
  height: 25rem;

  @media only screen and (max-width: 1000px) {
    flex-direction: column;
    // border-top: var(--border);
    align-items: center;
    height: auto;

    div:nth-child(3) {
    }
  }
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 3rem;
`;

const LogoImage = styled.img`
  height: 7.5rem;

  @media only screen and (max-width: 1000px) {
    height: 6rem;
  }
`;

const LogoText = styled.div`
  font-family: "Chakra Petch", sans-serif;
  font-size: 2.4rem;
  font-weight: var(--bold);
  text-transform: uppercase;
  margin-left: 1rem;

  @media only screen and (max-width: 1000px) {
    font-size: 2.4rem;
  }
`;

const Disclaimer = styled.div`
  white-space: nowrap;
  font-size: 1.4rem;
  margin-top: 2rem;
  align-items: flex-end;
  justify-content: flex-end;

  a {
    font-size: 1.4rem;
    text-decoration: underline;
  }

  @media only screen and (max-width: 1000px) {
    margin-top: 2rem;
    // font-size: 1.2rem;

    // a {
    //   font-size: 1.2rem;
    //   text-decoration: underline;
    // }
  }
`;

// const HideMobile = styled.div`
//   display: flex;

//   @media only screen and (max-width: 1000px) {
//     display: none;
//   }
// `;

const SocialsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Footer = () => {
  const location = useLocation();
  return (
    <StyledFooter>
      <BackgroundImage src={footerImage} />
      <Content>
        {/* <HideMobile> */}
        <Logo>
          <LogoImage src={bitcoin} />
          <LogoText>billionaires</LogoText>
        </Logo>
        {/* </HideMobile> */}
        {/* Hide email newsletter on Airdrop form. This should be cleaned up later*/}
        {!location.pathname.toLowerCase().includes("airdrop") /*&& <Newsletter />*/}
        <SocialsContainer>
          <div>
            {/* <Socials /> */}
          </div>
          <Disclaimer>Copyright © 2021, FizzPow Labs, Inc.</Disclaimer>
        </SocialsContainer>
      </Content>
    </StyledFooter>
  );
};

export default Footer;
