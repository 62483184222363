import React from "react";
import styled from "styled-components";
import { useDevice } from "../hooks/use-device";

const StyledSocial = styled.div`
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary);
  color: var(--bg);
  cursor: pointer;
  @media only screen and (max-width: 1000px) {
    height: 5.5rem;
    width: 5.5rem;
  }    
`;

const Image = styled.img`
  width: 2.5rem;
  @media only screen and (max-width: 1000px) {
    width: 2.75rem;
  }    

`;

type Props = {
  asset: string;
  link: string;
  mobileAsset: string;
};

const Social = (props: Props) => {
  const { isMobile } = useDevice();

  return (
    <StyledSocial
      onClick={() => {
        (window as any).open(props.link, "_blank")?.focus();
      }}
    >
      <Image src={isMobile ? props.mobileAsset : props.asset} />
    </StyledSocial>
  );
};

export default Social;
