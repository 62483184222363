import React from "react";
import styled from "styled-components";
import HomePage from "./pages/home/HomePage";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Footer from "./components/Footer";
import PrivacyPolicyPage from "./pages/privacy/PrivacyPolicyPage";
// import MintPage from "./pages/mint/MintPage";
// import PresalePage from "./pages/presale/PresalePage";
// import AdminPage from "./pages/admin/AdminPage";

const StyledApp = styled.div`
  width: 100%;
  min-height: 100vh;
  padding: 3rem 10rem;
  margin: auto;
  background-color: var(--bg);
  overflow: hidden;

  @media only screen and (max-width: 1000px) {
    padding: 2rem 2rem;
  }
`;

const Content = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100vh - 250px);

  @media only screen and (max-width: 1000px) {
    min-height: calc(100vh - 387px);
  }
`;

const App = () => {
  return (
    <StyledApp>
      <Router>
        <Switch>
          <Content>
            {/* <Route path="/mint">
              <MintPage />
            </Route> */}
            {/* <Route path="/admin">
              <AdminPage />
            </Route> */}
            {/* <Route exact path="/presale">
              <PresalePage />
            </Route> */}
            <Route exact path="/">
              <HomePage />
            </Route>
            <Route exact path="/Privacy">
              <PrivacyPolicyPage />
            </Route>
          </Content>
        </Switch>
        <Footer />
      </Router>
    </StyledApp>
  );
};

export default App;
