import React from "react";
import styled from "styled-components";
import SmallHeader from "../../components/SmallHeader";
import SubHeader from "../../components/SubHeader";

const StyledTermsPage = styled.div`
  position: relative;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 3rem 0;
`;

const TermsWrapper = styled.div`
    font-size: 2.2rem;
    line-height: 3.5rem;
    width: 100%;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
`;

// const TermsList = styled.ul`
//     font-size: 1em;
//     margin-left: 2em;
//     margin-bottom: 1em;
//     margin-top: 1em;
// `;

// const TermsItem = styled.li`
//     font-size: 1em;
// `;
const TermsParagraph = styled.p`
    font-size:1em;
    margin-bottom: 1em;
    margin-top: 1em;
`;

const PrivacyPolicyPage = () => {
    return (
        <StyledTermsPage>
            <SmallHeader />
            <TermsWrapper>
                <SubHeader>
                    BITCOIN BILLIONAIRES PRIVACY POLICY
                </SubHeader>
                <br />
                <TermsParagraph>
                    Last Updated: November 15, 2021
                </TermsParagraph>
                <TermsParagraph>
                    Welcome to Bitcoin Billionaires (“Billionaires”), a digital non-fungible token (“NFT”) by FizzPow Games, Inc.(“FizzPow”). Billionaires is a decentralized application and digital assets platform operating on the Ethereum blockchain. Billionaires uses specially-developed smart contracts made by FizzPow that enable the purchasing of digital creations on a peer-to-peer digital marketplace. This privacy policy (“Policy”) describes how FizzPow and its related companies ("Billionaires" or “we”) collect, use and share personal information of consumer users of this website, https://billionaires.io/ (the “Website”). This Policy also applies to any of our other websites that post this Policy. We may change this privacy policy at any time. If we make any changes, we will change the Last Updated date above.
                </TermsParagraph>
                <TermsParagraph>
                    COLLECTION OF PERSONAL INFORMATION
                </TermsParagraph>
                <TermsParagraph>
                    We may collect information that you provide to us directly on our Website. We may also automatically log information about you and your computer. For example, when visiting our Website, we may log your computer operating system type, browser type, browser language, the web pages you viewed on the Website, how long you spent on a page of the Website, and/or access times and information about your use of and actions on our Website.
                </TermsParagraph>
                <TermsParagraph>
                    USE OF PERSONAL INFORMATION
                </TermsParagraph>
                <TermsParagraph>
                    We may use your personal information for any of the following purposes: (a) to operate, maintain, and improve our Website, products, and services; (b) to send information including confirmations, technical notices, updates, security alerts, and support and administrative messages regarding our Website and its services; (c) to protect, investigate, and deter against fraudulent, unauthorized, or illegal activity; and/or (d) to provide and deliver products and services via the Website.
                </TermsParagraph>
                <TermsParagraph>
                    SHARING OF PERSONAL INFORMATION
                </TermsParagraph>
                <TermsParagraph>
                    We may share personal information for legal, protection, and safety purposes, including, but not limited to: (a) compliance with laws; (b) responding to lawful requests and legal processes; and/or (c) protecting the rights and property of FizzPow and its agents, customers, and others, such as enforcing our agreements, policies, and terms of use.
                </TermsParagraph>
            </TermsWrapper>
        </StyledTermsPage>
    );
};

export default PrivacyPolicyPage;
