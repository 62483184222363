import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TierType } from "../pages/home/tiersSchema";
import { RootState } from "./store";

export type Attribute = {
  trait_type: string;
  value: string;
};

export type Metadata = {
  image: string;
  attributes: Attribute[];
};

export type BillionareType = {
  id: string;
  metadata: Metadata;
};

interface BillionairesState {
  billionaires: BillionareType[];
  gallery: Metadata[];
  tiers: TierType[];
}

const initialState: BillionairesState = {
  billionaires: [],
  gallery: [],
  tiers: [],
};

export const billionairesSlice = createSlice({
  name: "billionaires",
  initialState,
  reducers: {
    setBillionaires: (state, action: PayloadAction<BillionareType[]>) => {
      state.billionaires = action.payload;
    },
    setGallery: (state, action: PayloadAction<Metadata[]>) => {
      state.gallery = action.payload;
    },
    setTiers: (state, action: PayloadAction<TierType[]>) => {
      state.tiers = action.payload;
    },
  },
});

export const {
  setBillionaires,
  setGallery,
  setTiers,
} = billionairesSlice.actions;

export const selectBillionaires = (state: RootState) =>
  state.billionaires.billionaires;
export const selectGallery = (state: RootState) => state.billionaires.gallery;
export const selectTiers = (state: RootState) => state.billionaires.tiers;

export default billionairesSlice.reducer;
