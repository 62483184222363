import React from "react";
import styled from "styled-components";
// import Buy from "./Buy";
// import Faq from "./Faq";
// import Giveaways from "./Giveaways";
// import Heritage from "./Heritage";
// import Highlights from "./Highlights";
import Species from "./Species";
import Landing from "./Landing";
// import Roadmap from "./Roadmap";
// import Specs from "./Specs";
// import Team from "./Team";
// import ImageSlide from "../../components/ImageSlide";

import BigHeader from "../../components/BigHeader";
const StyledHome = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const HomePage = () => {
  return (
    <StyledHome>

      <BigHeader />
      <Landing />
      {/* <ImageSlide hasSpacing /> */}
      {/* <ImageSlide width="50%" /> */}
      {/* <ImageSlide width="50%" hasSpacing /> */}
      {/* <Buy /> */}
      {/* <Specs /> */}
      {/* <Highlights /> */}
      <Species />
      {/* <Giveaways /> */}
      {/* <Roadmap /> */}
      {/* <Heritage /> */}
      {/* <Team /> */}
      {/* <Faq /> */}
    </StyledHome>
  );
};

export default HomePage;
