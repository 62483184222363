import React from "react";
import { useHistory, useLocation } from "react-router";
import styled from "styled-components";
// import GLOBALS from "../utils/globals";

type NavItemType = {
  label: string;
  route: string;
  external?: boolean;
};

const navItems: NavItemType[] = [
  // {
  //   label: "Docs",
  //   route: GLOBALS.DOCS_URL,
  //   external: true,
  // },
  // {
  //   label: "MissionX",
  //   route: "https://playmissionx.com",
  //   external: true,
  // }
];

type NavContainerProps = {
  center?: boolean;
  vertical?: boolean;
};

const NavContainer = styled.div`
  position: ${(props: NavContainerProps) =>
    props.center ? "absolute" : "relative"};
  display: flex;
  flex-direction: ${(props: NavContainerProps) =>
    props.vertical ? "column" : "row"};
  align-items: center;
  top: ${(props: NavContainerProps) => (props.center ? "50%" : "0")};
  left: ${(props: NavContainerProps) => (props.center ? "50%" : "0")};
  transform: ${(props: NavContainerProps) =>
    props.center ? "translate(-50%, -50%)" : "none"};

  @media only screen and (max-width: 1000px) {
    width: 100%;
    margin-bottom: 5rem;
  }
`;

type NavItemProps = {
  active: boolean;
  vertical?: boolean;
};

const NavItem = styled.div`
  text-transform: uppercase;
  font-family: "Chakra Petch", sans-serif;
  font-size: ${(props: NavItemProps) => (props.vertical ? "3rem" : "2rem")};
  font-weight: var(--medium);
  margin: ${(props: NavItemProps) => (props.vertical ? "2rem 0" : "0 1.5rem")};
  cursor: pointer;
  border-bottom: ${(props: NavItemProps) =>
    props.active ? "solid 1px var(--primary)" : "none"};
  padding: 0.4rem 0.5rem;

  @media only screen and (max-width: 1000px) {
    font-size: ${(props: NavItemProps) => (props.vertical ? "3rem" : "1.2rem")};
    margin: ${(props: NavItemProps) => (props.vertical ? "2rem 0" : "0")};
  }
`;

const NavItemSeperator = styled.div`
font-size:2rem;
  font-weight: var(--medium);
  &:after {
      content: "|";
    }
  @media only screen and (max-width: 1000px) {
    display:none;
  }
`;

type Props = {
  center?: boolean;
  vertical?: boolean;
  close?: () => void;
};

const NavItems = (props: Props) => {
  const history = useHistory();
  const location = useLocation();

  return (
    <>
      <NavContainer center={props.center} vertical={props.vertical}>
        {navItems.map((ni: NavItemType, i, items) => (
          <>
            <NavItem
              active={location.pathname === ni.route}
              onClick={() => {
                if (ni.external) {
                  (window as any).location = ni.route;
                } else {
                  history.push(ni.route);
                }
                if (props.close) { props.close(); }
              }}
              vertical={props.vertical}
            >
              {ni.label}
            </NavItem>
            {i + 1 !== items.length &&
              <NavItemSeperator />
            }</>
        ))}
      </NavContainer>
    </>
  );
};

export default NavItems;
