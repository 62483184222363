import React from "react";
import styled from "styled-components";
import twitter from "../assets/svgs/twitter.svg";
import twitterWhite from "../assets/svgs/twitter_white.svg";
import opensea from "../assets/svgs/opensea.svg";
import openseaWhite from "../assets/svgs/opensea_white.svg";
// import discord from "../assets/svgs/discord.svg";
// import discordWhite from "../assets/svgs/discord_white.svg";
// import youtube from "../assets/svgs/youtube.svg";
import Social from "./Social";
import GLOBALS from "../utils/globals";

type SocialType = {
  asset: string;
  mobileAsset: string;
  link: string;
};

const socials: SocialType[] = [
  {
    asset: twitter,
    mobileAsset: twitterWhite,
    link: GLOBALS.TWITTERURL,
  },
  // {
  //   asset: discord,
  //   mobileAsset: discordWhite,
  //   link: GLOBALS.DISCORDURL,
  // },
  {
    asset: opensea,
    mobileAsset: openseaWhite,
    link: GLOBALS.OPENSEA_URL,
  },
  // {
  //   asset: youtube,
  //   link: "https://google.com/",
  // },
];

const StyledSocials = styled.div`
  display: flex;
  justify-content: flex-end;

  div {
    margin: 0 0.7rem;
  }

  @media only screen and (max-width: 1000px) {
    margin-bottom: 1rem;
    justify-content: center;

    div {
      margin: 0 1.2rem;
    }    
  }
`;

const Socials = () => {
  return (
    <StyledSocials>
      {socials.map((social: SocialType) => (
        <Social
          asset={social.asset}
          link={social.link}
          mobileAsset={social.mobileAsset}
        />
      ))}
    </StyledSocials>
  );
};

export default Socials;
