import React from "react";
import styled, { keyframes } from "styled-components";
import b01 from "../assets/billionaires/bb-00001.png";
import b02 from "../assets/billionaires/bb-00002.png";
import b03 from "../assets/billionaires/bb-00003.png";
import b04 from "../assets/billionaires/bb-00004.png";
import b05 from "../assets/billionaires/bb-00005.png";
import b06 from "../assets/billionaires/bb-00006.png";
import b07 from "../assets/billionaires/bb-00007.png";
import b08 from "../assets/billionaires/bb-00008.png";
import b09 from "../assets/billionaires/bb-00009.png";
import b10 from "../assets/billionaires/bb-00010.png";
import b11 from "../assets/billionaires/bb-00011.png";
import b12 from "../assets/billionaires/bb-00012.png";
import b13 from "../assets/billionaires/bb-00013.png";

const images: string[] = [
  b01,
  b02,
  b03,
  b04,
  b05,
  b06,
  b07,
  b13,
  b08,
  b09,
  b10,
  b11,
  b12,
];

const StyledImageSlide = styled.div`
  position: relative;
  width: 100vw;
  overflow: hidden;
`;

const animation = keyframes`
	from {
		transform: translateX(0%);
	}
	to {
		transform: translateX(-50%);
	}
`;

const ImageContainer = styled.div`
  display: flex;
  width: ${(300 + 30) * images.length * 2}px;
  transform: translateX(0%);
  animation: ${animation} 75s linear infinite;
  justify-content: space-between;
  @media only screen and (max-width: 1000px) {
    animation: ${animation} 45s linear infinite;
    width: ${(200 + 20) * images.length * 2}px;
  }
`;

const Image = styled.img`
  // border-radius: 1rem;
  width: 300px;
  height: 300px;

  @media only screen and (max-width: 1000px) {
    width: 200px;
    height: 200px;
  }
`;

const ImageSlide = () => {
  const duplicatedImages = [...images, ...images];

  return (
    <StyledImageSlide>
      <ImageContainer>
        {duplicatedImages.map((i: string) => (
          <Image src={i} />
        ))}
      </ImageContainer>
    </StyledImageSlide>
  );
};

export default ImageSlide;
