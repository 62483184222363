import React, { useState } from "react";
import styled from "styled-components";
import bitcoin from "../assets/bitcoin.png";
import NavItems from "./NavItems";
import Socials from "./Socials";

import { useHistory } from "react-router";

const StyledHeader = styled.div`
  position: relative;
  width: 100%;
  // margin-bottom: 1rem;

  @media only screen and (max-width: 1000px) {
    // margin-bottom: 1rem;
  }
`;

const HeaderImageContainer = styled.div`
  // padding: 1.5rem;
  // padding-bottom: 3rem;
  position: relative;

  // top: 0;
  left: -10rem;
  transform: translateY(-5%);

  max-height: 55rem;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  // position: absolute;
  // top: 0;
  // left: -10rem;
  // width: calc(100% + 20rem);
  // transform: translateY(-35%);
  width: 100vw;

  @media only screen and (max-width: 1000px) {
    left: -2rem;
  }
`;

const Content = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // max-width: 1000px;
  margin: auto;
`;

const LogoImage = styled.img`
  height: 7.5rem;
  cursor: pointer;

  @media only screen and (max-width: 1000px) {
    height: 5.5rem;
  }
`;

const HideMobile = styled.div`
  display: flex;

  @media only screen and (max-width: 1000px) {
    display: none;
  }
`;

type HamburgerLineProps = {
  small?: boolean;
};

const HamburgerLine = styled.div`
  height: 2px;
  border-radius: 1px;
  background-color: white;
  width: ${(props: HamburgerLineProps) => (props.small ? "60%" : "100%")};
`;

const Hamburger = styled.div`
  height: 4.8rem;
  width: 4.8rem;
  border-radius: 50%;
  background-color: var(--primary);
  padding: 1.6rem 1.4rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media only screen and (min-width: 1001px) {
    display: none;
  }
`;

const MobilePopup = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: var(--primary);
  z-index: 1;
`;

const ExitButton = styled.button`
  padding: 2rem 2.7rem;
  font-size: 5.5rem;
  line-height: 3.7rem;
  font-weight: 500;
  color: white;
  position: absolute;
  font-family: "Chakra Petch", sans-serif;
  text-transform: lowercase;
  top: 0;
  right: 0;
`;

const HeaderTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  top: 15%;
  align-items: center;
  text-align: center;
  height: 75%;
  width: 99%;
  margin-top: 10rem;
  // margin-bottom: 10rem;
  @media only screen and (max-width: 1000px) {
    margin-top: 8rem;
    margin-bottom: 0rem;
    // top: 19%;
  }
`;

const HeaderText = styled.h1`
  font-size: 9.5rem;
  font-weight: var(--bold);
  // text-align: center;
  // max-width: 110rem;
  margin-top: 2rem;
  // margin-bottom: 5rem;
  text-transform: uppercase;
  // -webkit-text-stroke:1px black;

  text-shadow: 0 0 12px #ff0000, 0 0 20px #0000ff;

  @media only screen and (max-width: 1000px) {
    margin-top: 1rem;
    font-size: 3.5rem;
    line-height: 100%;
    max-width: none;
    // margin-bottom: 1rem;
  }
`;


const SmallHeader = () => {
  // const location = useLocation();
  const history = useHistory();
  const [mobilePopup, setMobilePopup] = useState(false);

  return (
    <StyledHeader>
      <HeaderImageContainer>
        <HeaderTextContainer>
          <HeaderText></HeaderText>
        </HeaderTextContainer>
      </HeaderImageContainer>
      <Content>
        <LogoImage src={bitcoin} onClick={() => history.push("/")} />
        <HideMobile>
          <NavItems center />
        </HideMobile>
        <HideMobile>
          <Socials />
        </HideMobile>
        <Hamburger onClick={() => setMobilePopup(true)}>
          <HamburgerLine small />
          <HamburgerLine />
          <HamburgerLine small />
        </Hamburger>
      </Content>
      {mobilePopup && (
        <MobilePopup>
          <ExitButton onClick={() => setMobilePopup(false)}>X</ExitButton>
          <NavItems vertical close={() => setMobilePopup(false)} />
          <Socials />
        </MobilePopup>
      )}
    </StyledHeader>
  );
};

export default SmallHeader;
